<template>
  <div>
    <!-- <div class="d-flex">
      <h3 class="mb-0 ml-0 color4 font-weight-bolder">
        {{ productData.id == 0 ? "ADD" : "EDIT" }} Product
      </h3>
    </div> -->
    <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
    <form-wizard color="#7367F0" :title="productData.id == 0 ? 'Add' + ' Product' : 'Edit' + ' Product'"
      :subtitle="null" finish-button-text="Submit" back-button-text="Previous" class="mb-3" @on-complete="onSubmit">
        <!-- product details tab -->
        <tab-content title="Product Details" :before-change="validationForm">
          <validation-observer ref="accountRules" tag="form">
            <b-row class="mb-2">
              <!-- <b-col cols="12" class="mb-2">
              <h4 class="mb-0">Product Details</h4>
            </b-col>
            <b-col
              cols="12"
              md="12"
              class="mt-2"
              key="product_has_image"
              v-if="imageChanged === false && productData.id > 0"
            >
            </b-col> -->
              <b-col md="12" class="d-flex align-item-center pb-3">
                <b-form-group label="Product Cover Image:" label-for="cover_image_path" label-cols-md="5" style="
                  display: flex;
                  justify-content: start;
                  align-items: start;
                  gap: 10px;
                  width: 100%;
                ">
                  <el-image v-if="imageChanged === false && productData.id > 0" style="
                    aspect-ratio: 1 /1;
                    padding: 0;
                    max-width: 150px;
                    border-radius: 5px;
                  " fit="cover" :src="`${uploadUrl}${productData.cover_image_path}`" :preview-src-list="documentList">
                  </el-image>
                  <el-upload class="avatar-uploader" list-type="picture-card" ref="upload_image" :action="uploadUrl"
                    :headers="headerObj" name="cover_image_path" style="display: flex" multiple :limit="1"
                    :data="productData" :on-change="onChangeCoverImage" :on-remove="onChangeCoverImage"
                    :auto-upload="false">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Product Name" label-for="name" label-cols-md="3" class="required">
                  <validation-provider #default="{ errors }" name="name" rules="required">
                    <b-form-input id="name" v-model="productData.name" :state="errors.length > 0 ? false : null"
                      placeholder="Product Name" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Brand" label-for="brand_id" label-cols-md="3" class="">
                  <validation-provider #default="{ errors }" name="brand_id" rules="">
                    <div class="form-col-select">
                      <v-select :state="errors.length > 0 ? false : null" id="brand_id" background-color="white"
                        v-model="productData.brand_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="brandOptions" :clearable="true" label="name" :reduce="(option) => option.id"
                        placeholder="Select brand" />
                      <feather-icon size="18" icon="ChevronDownIcon" />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Product Category" label-for="product_categories" label-cols-md="3"
                  class="required">
                  <validation-provider #default="{ errors }" name="product_categories" rules="required">
                    <div class="form-col-select">
                      <v-select :state="errors.length > 0 ? false : null" id="product_categories"
                        background-color="white" v-model="productData.product_categories"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="productCategoryOptions"
                        :clearable="false" label="name" multiple :reduce="(option) => option.id"
                        placeholder="Select product categories" />
                      <feather-icon size="18" icon="ChevronDownIcon" />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Product Price" label-for="price" label-cols-md="3" class="required">
                  <validation-provider #default="{ errors }" name="price" rules="required">
                    <b-form-input id="price" v-model="productData.price" :state="errors.length > 0 ? false : null"
                      placeholder="Product Price" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr />
            <b-row class="mb-2">
              <b-col cols="12" class="mb-2">
                <h4 class="mb-0">Shipping Specifications</h4>
              </b-col>
              <b-col md="4">
                <b-form-group label="Package Weight" label-for="weight" label-cols-md="3" class="required">
                  <validation-provider #default="{ errors }" name="weight" rules="required">
                    <b-form-input id="weight" type="number" step="0.01" v-model="productData.weight"
                      :state="errors.length > 0 ? false : null" placeholder="Package Weight" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="8">
                <b-form-radio v-model="productData.unit_id" plain name="unit_id" value="1">
                  KG
                </b-form-radio>
                <b-form-radio v-model="productData.unit_id" plain name="unit_id" value="2">
                  G
                </b-form-radio>
              </b-col>
              <b-col md="4">
                <b-form-group label="Length (cm)" label-for="length" label-cols-md="3" class="required">
                  <validation-provider #default="{ errors }" name="length" rules="required">
                    <b-form-input id="length" type="number" step="0.01" v-model="productData.length"
                      :state="errors.length > 0 ? false : null" placeholder="Package Length" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Width (cm)" label-for="width" label-cols-md="3" class="required">
                  <validation-provider #default="{ errors }" name="width" rules="required">
                    <b-form-input id="width" type="number" step="0.01" v-model="productData.width"
                      :state="errors.length > 0 ? false : null" placeholder="Package Width" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Height (cm)" label-for="height" label-cols-md="3" class="required">
                  <validation-provider #default="{ errors }" name="height" rules="required">
                    <b-form-input id="height" type="number" step="0.01" v-model="productData.height"
                      :state="errors.length > 0 ? false : null" placeholder="Package Height" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label-for="is_dangerous">
                  <validation-provider #default="{ errors }" name="is_dangerous" rules="required">
                    <b-form-checkbox :checked="productData.is_dangerous ? true : false" @change="
                      productData.is_dangerous = !productData.is_dangerous
                      " class="custom-control-success" name="check-button" switch inline />
                    {{
                      productData.is_dangerous
                        ? "Is Dangerous Goods"
                        : "Is Dangerous Goods"
                    }}
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr />
            <b-row class="mb-2">
              <b-col cols="12" class="mb-2">
                <h4 class="mb-0">Product Warranty</h4>
              </b-col>
              <b-col cols="12">
                <b-form-group label-for="has_warranty">
                  <validation-provider #default="{ errors }" name="has_warranty" rules="required">
                    <b-form-checkbox :checked="productData.has_warranty ? true : false" @change="
                      productData.has_warranty = !productData.has_warranty
                      " class="custom-control-success" name="check-button" switch inline />
                    {{
                      productData.has_warranty
                        ? "Product Warranty Included"
                        : "Product Warranty Included"
                    }}
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" v-if="productData.has_warranty">
                <b-form-group label="Warranty Type" label-for="warranty_type_id" label-cols-md="3" class="required">
                  <validation-provider #default="{ errors }" name="warranty_type_id" rules="required">
                    <div class="form-col-select">
                      <v-select :state="errors.length > 0 ? false : null" id="warranty_type_id" background-color="white"
                        v-model="productData.warranty_type_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="warrantyOptions" :clearable="true" label="name" :reduce="(option) => option.id"
                        placeholder="Select Warranty Type" />
                      <feather-icon size="18" icon="ChevronDownIcon" />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" v-if="productData.has_warranty">
                <b-form-group label="Warranty Period" label-for="warranty_period" label-cols-md="3" class="required">
                  <validation-provider #default="{ errors }" name="warranty_period" rules="required">
                    <div class="form-col-select">
                      <v-select :state="errors.length > 0 ? false : null" id="warranty_period" background-color="white"
                        v-model="productData.warranty_period" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="warrantyPeriodOptions" :clearable="true" label="name"
                        :reduce="(option) => option.name" placeholder="Select Warranty Period" />
                      <feather-icon size="18" icon="ChevronDownIcon" />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" v-if="productData.has_warranty">
                <b-form-group label="Warranty Policy" label-for="warranty_policy" label-cols-md="3" class="required">
                  <validation-provider #default="{ errors }" name="warranty_policy" rules="required">
                    <b-form-input id="warranty_policy" v-model="productData.warranty_policy"
                      :state="errors.length > 0 ? false : null" placeholder="Warranty Policy" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr />
            <b-row class="mb-2">
              <b-col md="12">
                <b-form-group label="What's in the box?" label-for="box_detail" label-cols-md="3" class="required">
                  <validation-provider #default="{ errors }" name="box_detail" rules="required">
                    <b-form-input id="box_detail" v-model="productData.box_detail"
                      :state="errors.length > 0 ? false : null" placeholder="What's in the box?" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- product Highlight tab -->
        <tab-content title="Product Highlight" :before-change="validationFormHighlight">
          <validation-observer ref="highlightRules" tag="form">
            <h4 class="mb-2 mt-2">Product Highlight</h4>
            <b-form-group label="">
              <validation-provider #default="{ errors }" name="highlight" rules="required">
                <div class="quill-editor">
                  <!-- <el-upload
                  class="avatar-uploader"
                  :action="uploadUrl"
                  :headers="headerObj"
                  name="file"
                  :show-file-list="false"
                  :on-success="uploadSuccess"
                >
                </el-upload> -->
                  <!--富文本编辑器组件-->
                  <quill-editor v-model="productData.highlight" :content="productData.highlight"
                    :options="highlightOption" ref="QuillEditor">
                  </quill-editor>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>
            </b-form-group>
          </validation-observer>
        </tab-content>

        <!-- product combos tab -->
        <tab-content title="Product Variants" :before-change="validationFormVariant">
          <h4 class="mb-2 mt-2">Product Variants & Images</h4>
          <validation-observer ref="variantRules" tag="form">
            <b-row v-for="(combo, index) in productData.product_variant" :key="`product_variants_${index}`"
              class="pb-1">
              <b-col cols="12">
                <div class="d-flex border rounded">
                  <b-row class="flex-grow-1 p-2">
                    <b-col md="3">
                      <b-form-group label="Color" label-for="color" label-cols-md="3">
                        <v-select id="color" v-model="combo.color_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="colorOptions" :clearable="false"
                          label="color" :reduce="(option) => option.id" placeholder="Product color">
                          <template slot="option" slot-scope="option">
                            <b-badge href="#" class="d-block" v-bind:style="{
                              'background-color': option.color,
                            }">
                              {{ option.color }}
                            </b-badge>
                          </template>
                        </v-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Size" label-for="size" label-cols-md="3">
                        <v-select id="size" v-model="combo.size_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="sizeOptions" :clearable="false" label="size" :reduce="(option) => option.id"
                          placeholder="Product size" />
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Type" label-for="type" label-cols-md="3">
                        <v-select id="type" v-model="combo.type_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="typeOptions" :clearable="false" label="type" :reduce="(option) => option.id"
                          placeholder="Product type" />
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Stock" label-for="stock" label-cols-md="3" class="required">
                        <validation-provider #default="{ errors }" name="stock" rules="required">
                          <b-form-input id="stock" v-model="combo.stock" :state="errors.length > 0 ? false : null"
                            placeholder="Stock" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                    <feather-icon size="16" icon="PlusIcon" class="cursor-pointer text-success"
                      @click="addProductCombination" />

                    <feather-icon size="16" icon="XIcon" class="cursor-pointer text-danger"
                      @click="removeProductCombination(index, combo)" v-if="
                        productData &&
                        productData.product_variant &&
                        productData.product_variant.length > 1
                      " />
                  </div>
                </div>
                <br />
              </b-col>
              <div style="
                display: flex;
                justify-content: start;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px;
                margin-bottom: 10px;
              ">
                <b-col class="mt-2" v-for="(image, index) in combo.variant_image" :key="'image' + index"
                  style="padding: 0">
                  <div style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                  ">
                    <!-- {{ uploadUrl }} {{ image.media_path }} -->
                    <el-image :src="`${uploadUrl}${image.media_path}`" style="
                      width: 100%;
                      aspect-ratio: 1 /1;
                      padding: 0;
                      min-width: 90px;
                      max-width: 140px;
                      border-radius: 5px;
                    " :preview-src-list="combo.variant_image.map((x) => uploadUrl + x.media_path)
                      " :alt="image.alt">
                    </el-image>
                    <b-button variant="danger" class="delete-button" @click="deleteImage(image.id)">Delete</b-button>
                  </div>
                </b-col>
              </div>




              <b-col cols="12">
                <div class="upload-container">
                  <!-- Draggable image list -->
                  <draggable :value="getUploadFilesForVariant(index)"
                    @input="updateUploadFilesForVariant($event, index)" @start="drag = true" @end="dragEnd"
                    class="el-upload-list el-upload-list--picture-card" :options="{ animation: 200 }">
                    <div v-for="(file, fileIndex) in getUploadFilesForVariant(index)" :key="fileIndex"
                      class="el-upload-list__item">
                      <img :src="file.url" class="el-upload-list__item-thumbnail">
                      <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-delete" @click="handleRemove(file, index)">
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                    </div>
                  </draggable>

                  <!-- Upload button -->
                  <el-upload class="avatar-uploader" :show-file-list="false" list-type="picture-card" ref="upload_image"
                    :action="uploadUrl" :headers="headerObj" name="media_path[]" multiple :limit="10" :data="combo"
                    :on-change="(file, fileList) => onChange(file, fileList, index)"
                    :on-remove="(file, fileList) => onRemove(file, fileList, index)" :auto-upload="false">
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </b-col>
              <!-- <b-col
              cols="12"
              md="3"
              class="mt-2"
              v-for="(image, index) in combo.variant_images"
              :key="index"
              v-if="combo.id > 0"
            >
              <el-image
                style="width: 100%; aspect-ratio: 2 /1; padding: 0"
                fit="cover"
                :src="`${uploadUrl}${image.media_path}`"
                :preview-src-list="[`${uploadUrl}${image.media_path}`]"
                :alt="image.alt"
              >
              </el-image>
            </b-col> -->
            </b-row>
            <hr />
            <!-- <b-row>
            <b-col
              cols="3"
              md="3"
              class="mt-2"
              v-for="(image, index) in productData.product_image"
              :key="'image' + index"
            >
              <el-image
                :src="`${uploadUrl}${image.media_path}`"
                :alt="image.alt"
              >
              </el-image>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Product Images:"
                label-for="file"
                label-cols-md="4"
              >
                <el-upload
                  class="avatar-uploader"
                  list-type="picture-card"
                  ref="upload_image"
                  :action="uploadUrl"
                  :headers="headerObj"
                  name="product_image[]"
                  multiple
                  :limit="10"
                  :data="productData"
                  :on-change="onChangeMain"
                  :on-remove="onRemoveMain"
                  :auto-upload="false"
                >
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </b-form-group>
            </b-col>
          </b-row> -->
          </validation-observer>
        </tab-content>

        <!-- product Description tab -->
        <tab-content title="Product Description" :before-change="validationFormDescription">
          <validation-observer ref="descriptionRules" tag="form">
            <h4 class="mb-2 mt-2">Product Description</h4>
            <b-form-group label="">
              <validation-provider #default="{ errors }" name="description" rules="required">
                <div class="quill-editor">
                  <!-- <el-upload
                  class="avatar-uploader"
                  :action="uploadUrl"
                  :headers="headerObj"
                  name="file"
                  :show-file-list="false"
                  :on-success="uploadSuccess"
                >
                </el-upload> -->
                  <!--富文本编辑器组件-->
                  <quill-editor v-model="productData.description" :content="productData.description"
                    :options="editorOption" ref="QuillEditor">
                  </quill-editor>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>
            </b-form-group>
          </validation-observer>
        </tab-content>

        <!-- product Shipping tab -->
        <tab-content title="Product Shipping & Delivery" :before-change="validationFormShipping">
          <validation-observer ref="shippingRules" tag="form">
            <h4 class="mb-2 mt-2">Product Shipping & Delivery</h4>
            <b-form-group label="">
              <validation-provider #default="{ errors }" name="shipping" rules="required">
                <div class="quill-editor">
                  <!-- <el-upload
                  class="avatar-uploader"
                  :action="uploadUrl"
                  :headers="headerObj"
                  name="file"
                  :show-file-list="false"
                  :on-success="uploadSuccess"
                >
                </el-upload> -->
                  <!--富文本编辑器组件-->
                  <quill-editor v-model="productData.shipping" :content="productData.shipping" :options="editorOption"
                    ref="QuillEditor">
                  </quill-editor>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>
            </b-form-group>
          </validation-observer>
        </tab-content>
        <template v-slot:footer="{
          activeTabIndex,
          setActiveTab,
          nextTab,
          prevTab,
          isLastStep,
          isFirstStep,
        }">
          <b-button variant="secondary" class="mr-2" @click="onCancel">
            Cancel
          </b-button>

          <b-button v-if="!isFirstStep" variant="outline-primary" @click="prevTab" class="mr-2">
            Previous
          </b-button>

          <b-button v-if="!isLastStep" variant="primary" @click="nextTab">
            Next
          </b-button>

          <b-button v-if="isLastStep" variant="primary" @click="onSubmit">
            Submit
          </b-button>
        </template>
      </form-wizard>
    </b-overlay>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BButton,
  BOverlay,
  BBadge,
  BFormRadio,
} from "bootstrap-vue";
// import { codeIcon } from "./code";
import flatPickr from "vue-flatpickr-component";
import textSlider from "@/components/slider/textSlider.vue";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import draggable from 'vuedraggable'

export default {
  data() {
    return {
      required,
      alphaNum,
      email,
      flatDateConfigForBirth1: {
        altInput: true,
        altFormat: "Y-m-d",
        allowInput: true,
        dateFormat: "Y-m-d",
        maxDate: "today",
      },
      variantUploadFiles: {}, // Object to store upload files for each variant
    };
  },
  directives: {
    Ripple,
  },
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BOverlay,
    textSlider,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BBadge,
    quillEditor,
    BFormRadio,
    draggable,
  },
  watch: {
    productData(newItem, oldItem) {
      return newItem.fatca_expiration_date
        ? (this.yearFilter = newItem.fatca_expiration_date)
        : (this.yearFilter = new Date().getFullYear());
    },
  },
  methods: {
    deleteImage(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Your action is final and you will not be able to retrieve the product variant image.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-product/deleteImage", id)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    onChangeMain(file, fileList) {
      // Find the index of the image in the product_image array
      const imageIndex = this.productData.product_image.findIndex(
        (img) => img.media_path === file.raw
      );

      if (imageIndex === -1) {
        // If image doesn't exist, push a new one
        this.productData.product_image.push({
          media_path: file.raw,
          alt: file.name || "New Image",
        });
      } else {
        // If image exists, update it
        this.productData.product_image[imageIndex].media_path = file.raw;
      }

      console.log(
        "Updated productData.product_image:",
        this.productData.product_image
      );
    },

    onRemoveMain(file, fileList) {
      // Find the index of the image in the product_image array
      const imageIndex = this.productData.product_image.findIndex(
        (img) => img.media_path === file.raw
      );

      if (imageIndex !== -1) {
        // If image exists, remove it
        this.productData.product_image.splice(imageIndex, 1);
      }

      console.log(
        "Updated productData.product_image after removal:",
        this.productData.product_image
      );
    },
    onChange(file, fileList, variantIndex) {
      if (file.status === 'ready') {
        const currentFiles = this.getUploadFilesForVariant(variantIndex);
        const newFile = {
          id: Date.now(),
          url: URL.createObjectURL(file.raw),
          raw: file.raw,
          variantIndex
        };

        this.updateUploadFilesForVariant([...currentFiles, newFile], variantIndex);
      }

      // Update product variant media_path
      if (!this.productData.product_variant[variantIndex].media_path) {
        this.productData.product_variant[variantIndex].media_path = [];
      }
      this.productData.product_variant[variantIndex].media_path = fileList.map(
        (item) => item.raw
      );
    },
    onRemove(file, fileList, variantIndex) {
      const currentFiles = this.getUploadFilesForVariant(variantIndex);
      const updatedFiles = currentFiles.filter(f => f.id !== file.id);
      this.updateUploadFilesForVariant(updatedFiles, variantIndex);

      // Update product variant media_path
      if (this.productData.product_variant[variantIndex]) {
        const mediaPathIndex = this.productData.product_variant[variantIndex].media_path.indexOf(file.raw);
        if (mediaPathIndex !== -1) {
          this.productData.product_variant[variantIndex].media_path.splice(mediaPathIndex, 1);
        }
      }
    },
    onChangeCoverImage(file, fileLists) {
      this.imageChanged = true;
      this.productData.cover_image_path = file.raw;
    },
    uploadSuccess(res) {
      // 获取富文本组件实例
      console.log({ res });
      let quill = this.$refs.QuillEditor.quill;
      // 如果上传成功
      if (res) {
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(length, "image", res.file_path);
        // 调整光标到最后
        quill.setSelection(length + 1);
      } else {
        // 提示信息，需引入Message
        this.$message.error("图片插入失败！");
      }
    },
    hide() {
      this.$router.replace("/products");
    },
    mounted() {
      this.validationForm();
      this.validationFormHighlight();
      this.validationFormDescription();
      this.validationFormShipping();
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormVariant() {
      return new Promise((resolve, reject) => {
        this.$refs.variantRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormHighlight() {
      return new Promise((resolve, reject) => {
        this.$refs.highlightRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormDescription() {
      return new Promise((resolve, reject) => {
        this.$refs.descriptionRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormShipping() {
      return new Promise((resolve, reject) => {
        this.$refs.shippingRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    onCancel() {
      this.$router.replace("/product/list");
    },
    onSubmit() {
      console.log(this.productData);
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;

          let formData = new FormData();

          // this.productData.media_path.forEach((item) => {
          //   formData.append("media_path[]", item);
          // });
          this.productData.product_variant.forEach((item, index) => {
            formData.append(`product_variant[${index}][id]`, item.id);
            if (item.color_id !== null) {
              formData.append(
                `product_variant[${index}][color_id]`,
                item.color_id
              );
            }
            if (item.size_id !== null) {
              formData.append(
                `product_variant[${index}][size_id]`,
                item.size_id
              );
            }
            if (item.type_id !== null) {
              formData.append(
                `product_variant[${index}][type_id]`,
                item.type_id
              );
            }
            formData.append(`product_variant[${index}][stock]`, item.stock);

            // Append media_path files
            if (item.media_path && item.media_path.length > 0) {
              item.media_path.forEach((file, fileIndex) => {
                formData.append(
                  `product_variant[${index}][media_path][${fileIndex}]`,
                  file
                );
              });
            }
          });

          formData.append(
            "cover_image_path",
            this.productData.cover_image_path
          );
          // this.productData.product_image.forEach((image, index) => {
          //   formData.append(`product_image[${index}]`, image.media_path);
          // });
          this.productData.product_categories.forEach((item) => {
            formData.append("product_categories[]", item);
          });
          formData.append("id", this.productData.id);
          if (this.productData.brand_id !== null) {
            formData.append("brand_id", this.productData.brand_id);
          }
          formData.append("unit_id", this.productData.unit_id);
          formData.append("name", this.productData.name);
          formData.append("price", this.productData.price);
          formData.append("description", this.productData.description);
          formData.append("highlight", this.productData.highlight);
          formData.append("shipping", this.productData.shipping);
          formData.append("weight", this.productData.weight);
          formData.append("length", this.productData.length);
          formData.append("width", this.productData.width);
          formData.append("height", this.productData.height);
          formData.append(
            "is_dangerous",
            this.productData.is_dangerous ? 1 : 0
          );
          formData.append("is_active", this.productData.is_active ? 1 : 0);
          formData.append(
            "has_warranty",
            this.productData.has_warranty ? 1 : 0
          );
          if (this.productData.has_warranty) {
            formData.append(
              "warranty_type_id",
              this.productData.warranty_type_id
            );
            formData.append(
              "warranty_period",
              this.productData.warranty_period
            );
            formData.append(
              "warranty_policy",
              this.productData.warranty_policy
            );
          }
          formData.append("box_detail", this.productData.box_detail);

          console.log(formData);
          store
            .dispatch("app-product/addProduct", formData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.$router.push(`/product/list`);
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    addControllingPerson() {
      this.productData.controlling_persons.push({
        name: "",
        email: "",
        identity_document: "",
        date_of_birth: null,
        person_type_id: null,
        birthday_reminder_bit: false,
      });
    },
    addProductHighlight() {
      this.productData.product_highlight.push({
        id: 0,
        highlight: null,
      });
    },
    removeProductHighlight(index, item) {
      // check number of record
      let record =
        this.productData &&
        this.productData.product_highlight &&
        this.productData.product_highlight.length;
      if (record <= 1) {
        this.makeToast(
          "danger",
          "Warning",
          "Please submit at least one product highlight."
        );
      } else {
        if (item.id > 0) {
          this.$swal({
            title: "Are you sure?",
            text: "Your action is final and you will not be able to retrieve the product highlight.",
            showCancelButton: true,
            confirmButtonText: "Delete",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.$store
                .dispatch("app-product/deleteProductHighlight", { id: item.id })
                .then((response) => {
                  this.$swal({
                    text: response.data.message,
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    showClass: {
                      popup: "animate__animated animate__bounceIn",
                    },
                    buttonsStyling: false,
                  });
                  this.$emit("refetch-data");
                })
                .catch((error) => {
                  this.$swal({
                    text: JSON.stringify(error.response.data.message),
                    icon: "error",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    showClass: {
                      popup: "animate__animated animate__bounceIn",
                    },
                    buttonsStyling: false,
                  });
                });
            }
          });
        } else {
          this.productData.highlights.splice(index, 1);
        }
      }
    },
    addProductCombination() {
      this.productData.product_variant.push({
        id: 0,
        color_id: null,
        size_id: null,
        type_id: null,
      });
    },
    removeProductCombination(index, item) {
      // check number of record
      let record =
        this.productData &&
        this.productData.product_variant &&
        this.productData.product_variant.length;
      if (record <= 1) {
        this.makeToast(
          "danger",
          "Warning",
          "Please submit at least one product record."
        );
      } else {
        if (item.id > 0) {
          this.$swal({
            title: "Are you sure?",
            text: "Your action is final and you will not be able to retrieve the product reecord.",
            showCancelButton: true,
            confirmButtonText: "Delete",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.$store
                .dispatch("app-product/deleteProductDetail", { id: item.id })
                .then((response) => {
                  this.$swal({
                    text: response.data.message,
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    showClass: {
                      popup: "animate__animated animate__bounceIn",
                    },
                    buttonsStyling: false,
                  });
                  this.$emit("refetch-data");
                })
                .catch((error) => {
                  this.$swal({
                    text: JSON.stringify(error.response.data.message),
                    icon: "error",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    showClass: {
                      popup: "animate__animated animate__bounceIn",
                    },
                    buttonsStyling: false,
                  });
                });
            }
          });
        } else {
          this.productData.product_variant.splice(index, 1);
        }
      }
    },
    dragEnd(evt) {
      this.drag = false;

      // Update the media_path array to match the new order
      const variantIndex = this.uploadFiles[0]?.index; // Assuming all files are for the same variant
      if (typeof variantIndex !== 'undefined') {
        this.productData.product_variant[variantIndex].media_path =
          this.uploadFiles.map(file => file.raw);
      }
    },
    handleRemove(file) {
      const index = this.uploadFiles.indexOf(file);
      if (index !== -1) {
        this.uploadFiles.splice(index, 1);

        // Also remove from product variant media_path
        const variantIndex = file.index;
        if (typeof variantIndex !== 'undefined') {
          const mediaPathIndex = this.productData.product_variant[variantIndex].media_path.indexOf(file.raw);
          if (mediaPathIndex !== -1) {
            this.productData.product_variant[variantIndex].media_path.splice(mediaPathIndex, 1);
          }
        }
      }
    },
    handlePreview(file) {
      // Handle file preview logic
    },
    getUploadFilesForVariant(variantIndex) {
      return this.variantUploadFiles[variantIndex] || [];
    },

    updateUploadFilesForVariant(files, variantIndex) {
      this.$set(this.variantUploadFiles, variantIndex, files);

      // Update the media_path array to match the new order
      if (this.productData.product_variant[variantIndex]) {
        this.productData.product_variant[variantIndex].media_path =
          files.map(file => file.raw);
      }
    },
  },
  props: {
    productData: {
      type: Object,
      required: true,
    },
    colorOptions: {
      type: Array,
      required: true,
    },
    sizeOptions: {
      type: Array,
      required: true,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
    productCategoryOptions: {
      type: Array,
      required: true,
    },
    brandOptions: {
      type: Array,
      required: true,
    },
    unitOptions: {
      type: Array,
      required: true,
    },
    warrantyOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    console.log(props);
    const resetProductData = () => {
      props.productData = JSON.parse(JSON.stringify({ id: 0 }));
    };

    const loading = ref(false);
    const imageChanged = ref(false);

    const warrantyPeriodOptions = ref([
      {
        name: "3 months",
      },
      {
        name: "6 months",
      },
    ]);

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetProductData);

    const yearFilter = ref(new Date().getFullYear());

    const uploadUrl = process.env.VUE_APP_IMAGE_URL;
    // const uploadUrl = ref(
    //   process.env.VUE_APP_SERVICE_URL + "/api/project/uploadFile"
    // );

    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };

    const editorOption = {
      modules: {
        toolbar: {
          container: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [
              {
                font: [
                  "SimSun",
                  "SimHei",
                  "Microsoft-YaHei",
                  "KaiTi",
                  "FangSong",
                  "Arial",
                ],
              },
            ],
            [{ align: [] }],
            ["clean"],
            ["link", "image"],
          ],
          handlers: {
            image: (value) => {
              console.log("image", value);
              if (value) {
                document.querySelector(".avatar-uploader input").click();
              } else {
                this.quill.format("image", false);
              }
            },
          },
        },
      },
    };
    const highlightOption = {
      modules: {
        toolbar: {
          container: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ list: "bullet" }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["link"],
          ],
          handlers: {
            image: (value) => {
              console.log("image", value);
              if (value) {
                document.querySelector(".avatar-uploader input").click();
              } else {
                this.quill.format("image", false);
              }
            },
          },
        },
      },
    };

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      imageChanged,
      yearFilter,
      uploadUrl,
      headerObj,
      editorOption,
      highlightOption,
      warrantyPeriodOptions,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: full;
  height: full;
  object-fit: cover !important;
}

.el-upload-list--picture-card {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .el-upload-list__item {
    position: relative;
    width: 148px;
    height: 148px;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    overflow: hidden;

    &-thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-actions {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: default;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: opacity 0.3s;

      &:hover {
        opacity: 1;
      }
    }

    &-delete {
      color: #fff;
      font-size: 18px;
      cursor: pointer;
    }
  }
}

.sortable-ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.upload-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: flex-start;

  .el-upload--picture-card {
    margin: 0;
  }
}

.el-upload-list--picture-card {
  margin: 0;
}
</style>

<style lang="scss" scoped>
hr {
  width: calc(100% - 1.5rem);
  margin-top: 0px;
}

input::placeholder {
  color: lightgrey !important;
}

@media screen and (max-width: 767px) {
  .curr-btn {
    top: 51%;
  }
}
</style>
